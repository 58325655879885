import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
// Products images

import blueDrum1 from "../assets/images/products/HDPE BLOW GRADE/BLUE DRUM 200 LT/1-blue-drum-200LT.webp"
import blueDrum2 from "../assets/images/products/HDPE BLOW GRADE/BLUE DRUM 200 LT/2-blue-drum-200LT.webp"
import blueDrum3 from "../assets/images/products/HDPE BLOW GRADE/BLUE DRUM 200 LT/3-blue-drum-200LT.jpeg"

import hdpeSmallGallon1 from "../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-1.jpeg"
import hdpeSmallGallon2 from "../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-2.jpeg"
import hdpeSmallGallon3 from "../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-3.jpeg"
import hdpeSmallGallon4 from "../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-4.jpeg"


import milkBottle1 from "../assets/images/products/HDPE BLOW GRADE/MILK BOTTLE/milk-bottle1.jpeg"
import milkBottle2 from "../assets/images/products/HDPE BLOW GRADE/MILK BOTTLE/milk-bottle2.jpeg"

import pe100_1 from "../assets/images/products/HDPE BLOW GRADE/PE 100/pe-100-1.jpeg"
import pe100_2 from "../assets/images/products/HDPE BLOW GRADE/PE 100/pe-100-2.jpeg"
import pe100_3 from "../assets/images/products/HDPE BLOW GRADE/PE 100/pe-100-3.jpeg"

import smallGallon1 from "../assets/images/products/HDPE BLOW GRADE/SMALL GALLON/small-gallon1.jpeg"
import smallGallon2 from "../assets/images/products/HDPE BLOW GRADE/SMALL GALLON/small-gallon2.jpeg"
import smallGallon3 from "../assets/images/products/HDPE BLOW GRADE/SMALL GALLON/small-gallon3.jpeg"
import { useState } from 'react'

// ----------------------------------------------------------------------------------------------------

const PopularServices = () => {

    const [currProducts, setCurrentProducts] = useState(
        {
            name: "Blue Drum 200 LT",
            service: [
                {
                    name: "Blue Drum 200 LT",
                    image: blueDrum1
                },
                {
                    name: "Blue Drum 200 LT",
                    image: blueDrum2
                },
                {
                    name: "Blue Drum 200 LT",
                    image: blueDrum3
                },
            ]
        },
    )

    const [activeBtn, setActiveBtn] = useState("Blue Drum 200 LT");

    const [modal, setModal] = useState(false)
    const [modalImage, setModalImage] = useState("");
    const [cateButtons, setCateButtons] = useState(false);


    const products = [
        {
            name: "Blue Drum 200 LT",
            service: [
                {
                    name: "Blue Drum 200 LT",
                    image: blueDrum1
                },
                {
                    name: "Blue Drum 200 LT",
                    image: blueDrum2
                },
                {
                    name: "Blue Drum 200 LT",
                    image: blueDrum3
                },
            ]
        },
        {
            name: "HDPE Small Gallon",
            service: [
                {
                    name: "HDPE Small Gallon",
                    image: hdpeSmallGallon1
                },
                {
                    name: "HDPE Small Gallon",
                    image: hdpeSmallGallon2
                },
                {
                    name: "HDPE Small Gallon",
                    image: hdpeSmallGallon3
                },
            ]
        },
        {
            name: "Milk Bottle",
            service: [
                {
                    name: "Milk Bottle",
                    image: milkBottle1
                },
                {
                    name: "Milk Bottle",
                    image: milkBottle2
                },
            ]
        },
        {
            name: "PE 100",
            service: [
                {
                    name: "PE 100",
                    image: pe100_1
                },
                {
                    name: "PE 100",
                    image: pe100_2
                },
                {
                    name: "PE 100",
                    image: pe100_3
                },
            ]
        },
        {
            name: "Small Gallon",
            service: [
                {
                    name: "Small Gallon",
                    image: smallGallon1
                },
                {
                    name: "Small Gallon",
                    image: smallGallon2
                },
                {
                    name: "Small Gallon",
                    image: smallGallon3
                },
            ]
        }
    ]

    const openModal = (image) => {
        setModal(true);
        setModalImage(image);
    }

    return (
        <>
            <div className="position-relative">
                <div className='text-center d-sm-none d-block'>
                    <button onClick={() => setCateButtons(true)} className='btn btn-outline-light'>
                        <FontAwesomeIcon
                            icon={faFilter}
                        ></FontAwesomeIcon>
                        <span className='ml-10 d-inline-block'>Filter Products</span>
                    </button>
                </div>
                
                {/* for mobile */}
                <div className={`text-center w-100 category-buttons d-sm-none ${cateButtons ? 'd-block' : 'd-none'} `}>
                    <span className='btnsCloseBtn' onClick={() => setCateButtons(false)}>&times;</span>
                    {
                        products.map((product, i) => {
                            return (
                                <button key={i} value={product.name}
                                    onClick={(e) => { setActiveBtn(e.target.value); setCurrentProducts(product) }}
                                    className={`product-btn mb-md-0 mb-4 ${product.name === activeBtn ? 'activeBtn' : ''}`}>
                                    {product.name}
                                </button>
                            )
                        })
                    }
                </div>

                {/* for desktop */}
                <div className={`text-center w-100 d-sm-block d-none`}>
                    {
                        products.map((product, i) => {
                            return (
                                <button key={i} value={product.name}
                                    onClick={(e) => { setActiveBtn(e.target.value); setCurrentProducts(product) }}
                                    className={`product-btn mb-md-0 mb-4 ${product.name === activeBtn ? 'activeBtn' : ''}`}>
                                    {product.name}
                                </button>
                            )
                        })
                    }
                </div>

                <div className='row justify-content-center mt-md-5 mt-2 pt-4'>
                    {
                        currProducts.service.map((servic, index) => {
                            return (
                                <div className="col-xl-3 col-md-4" key={index} onClick={() => openModal(servic.image)}>
                                    <div className='product-card shadow'>
                                        <img src={servic.image} alt="productImage" className='w-100' />
                                        <h5>{servic.name}</h5>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {/* <!-- The Modal --> */}
                <div className={`img-modal ${modal ? "show-img-modal" : ""}`}>
                    <span className="closeModal" onClick={() => setModal(false)}>&times;</span>
                    <img src={modalImage} id="img01" />
                </div>
            </div>

        </>
    )
}

export default PopularServices