import React from 'react'
import ProductList from './ProductList'


// Product images

import ppFruat1 from "../../assets/images/products/PP REGRINDE/PP FRUAT BASKET/pp-fruat-basket1.jpeg"
import ppFruat2 from "../../assets/images/products/PP REGRINDE/PP FRUAT BASKET/pp-fruat-basket2.jpeg"

import ppPallate from "../../assets/images/products/PP REGRINDE/PP PALLATE/pp-pallate.jpeg"
import ppPallate1 from "../../assets/images/products/PP REGRINDE/PP PALLATE/pp-pallate1.jpeg"
import ppPallate2 from "../../assets/images/products/PP REGRINDE/PP PALLATE/pp-pallate2.jpeg"
import ppPallate3 from "../../assets/images/products/PP REGRINDE/PP PALLATE/pp-pallate3.jpeg"
import ppPallate4 from "../../assets/images/products/PP REGRINDE/PP PALLATE/pp-pallate4.jpeg"

import ppcpBattery1 from "../../assets/images/products/PP REGRINDE/PPCP BATTERY/ppcp-battery1.jpeg"
import ppcpBattery2 from "../../assets/images/products/PP REGRINDE/PPCP BATTERY/ppcp-battery2.jpeg"
import ppcpBattery3 from "../../assets/images/products/PP REGRINDE/PPCP BATTERY/ppcp-battery3.jpeg"


import ppcpWhiteBucket1 from "../../assets/images/products/PP REGRINDE/PPCP WHITE BUCKET/pp-regrinde.jpeg"
import ppcpWhiteBucket2 from "../../assets/images/products/PP REGRINDE/PPCP WHITE BUCKET/ppcp-white-bucket1.jpeg"
import ppcpWhiteBucket3 from "../../assets/images/products/PP REGRINDE/PPCP WHITE BUCKET/ppcp-white-bucket2.jpeg"
import ppcpWhiteBucket4 from "../../assets/images/products/PP REGRINDE/PPCP WHITE BUCKET/ppcp-white-bucket3.jpeg"

// ---------------------------------------------------------------------------------------------------------------


const PPRegrinde = () => {

    const products = [
        {
            name: "PP Pallate",
            image: [ppPallate, ppPallate1, ppPallate2, ppPallate3, ppPallate4]
        },
        {
            name: "PP Fruit Basket",
            image: [ppFruat1, ppFruat2]
        },
        {
            name: "PPCP Battery",
            image: [ppcpBattery1, ppcpBattery2, ppcpBattery3]
        },
        {
            name: "PPCP White Bucket",
            image: [ppcpWhiteBucket1, ppcpWhiteBucket2, ppcpWhiteBucket3, ppcpWhiteBucket4]
        }
    ]

    return (
        <>
            <ProductList title="PP Regrinde" products={products} />
        </>
    )
}

export default PPRegrinde