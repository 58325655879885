import Counter from "./Counter";

const OrgariumCounter = () => {
  return (
    <div className="row">
      <div className="col-lg-3 col-md-6 col-sm-12 px-md-0 counter-item">
        <div className="counter-inner">
          <div className="text">
            <h2 className="number">
              <Counter end={55} />+
            </h2>
            <p className="fs-18">Satisfied Clients</p>
          </div>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 px-md-0 counter-item">
        <div className="counter-inner">
          <div className="text">
            <h2 className="number">
              <Counter end={45} />+
            </h2>
            <p className="fs-18">Expert Team Members</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 px-md-0 counter-item">
        <div className="counter-inner">
          <div className="text">
            <h2 className="number">
              <Counter end={450} />+
            </h2>
            <p className="fs-18">Tons of plastic monthly recycle</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12 px-md-0 counter-item">
        <div className="counter-inner">
          <div className="text">
            <h2 className="number">
              <Counter end={7000} />+
            </h2>
            <p className="fs-18">Total tons of plastic recycle</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrgariumCounter;

export const OrgariumCounter2 = () => (
  <div className="row">
    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className="single-counter-item mb-40">
        <div className="text">
          <h2 className="number">
            <Counter end={3652} />+
          </h2>
          <p>Saticfied Clients</p>
        </div>
      </div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className="single-counter-item mb-40">
        <div className="text">
          <h2 className="number">
            <Counter end={896} />+
          </h2>
          <p>Saticfied Clients</p>
        </div>
      </div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className="single-counter-item mb-40">
        <div className="text">
          <h2 className="number">
            <Counter end={945} />+
          </h2>
          <p>Saticfied Clients</p>
        </div>
      </div>
    </div>
    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className="single-counter-item mb-40">
        <div className="text">
          <h2 className="number">
            <Counter end={565} />+
          </h2>
          <p>Saticfied Clients</p>
        </div>
      </div>
    </div>
  </div>
);
