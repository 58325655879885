import React, { Fragment } from "react";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Service from "./components/services";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "../src/assets/css/default.css";
import "../src/assets/css/style.css.map";
import "../src/assets/vendor/slick/slick.css";
import "../src/assets/vendor/magnific-popup/dist/magnific-popup.css";
import "../src/assets/vendor/bootstrap/css/bootstrap.min.css";
import "../src/assets/vendor/animate.css";
import "../src/assets/vendor/nice-select/css/nice-select.css";
import "../src/assets/fonts/fontawesome/css/all.min.css";
import "../src/assets/fonts/flaticon/flaticon.css";
import "./assets/css/style.css";
import HdpeBlowGrade from "./components/HdpeBlowGrade";
import ScrollToTop from "./components/ScrollToTop";

// products pages
import HdpeBlowGrade1 from "./components/Products/HdpeBlowGrade1";
import PPRegrinde from "./components/Products/PPRegrinde";
import ProductHdpeInjection from "./components/Products/ProductHdpeInjection";

const App = () => {
  return (
    <div>
      <Router>
        <Fragment>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/about" element={<About />}></Route>
            <Route exact path="/service" element={<Service />}></Route>

            <Route exact path="/hdpe-blow-grade" element={<HdpeBlowGrade1 />}></Route>
            <Route exact path="/pp-regrinde" element={<PPRegrinde />}></Route>
            <Route exact path="/product-hdpe-injection" element={<ProductHdpeInjection />}></Route>

            {/* <Route
              exact
              path="/hdpe-blow-grade"
              element={<HdpeBlowGrade />}
            ></Route> */}
            <Route exact path="/contact" element={<Contact />}></Route>
          </Routes>
        </Fragment>
      </Router>
    </div>
  );
};

export default App;
