import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import OrgariumCounter from "../layouts/OrgariumCounter";
import ProgressBar from "../layouts/ProgressBar";
import Layout from "../layouts/Layout";
import { testimonialSliderOne } from "../layouts/sliderProps";
import about1 from "../assets/images/banner/aboutus-first.jpg";
import about2 from "../assets/images/banner/aboutus2.png";
import team1 from "../assets/images/team/mohd-zuber1.png";
import team2 from "../assets/images/team/team2.png";
import team3 from "../assets/images/team/m_ashfaq.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import aboutBg from "../assets/images/banner/about-us.png";
import {
  faCheckCircle,
  faCircleArrowRight,
  faQuoteRight,
  faPhoneAlt,
  faAngleRight,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
const AboutUs = () => {

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>About Us - Almajal Recycling</title>
      </Helmet>
      <section
        className="page-banner bg_cover position-relative z-1"
        style={{ backgroundImage: `url(${aboutBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <div className="page-title">
                <h1>About Us</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link to="/">
                      Home{" "}
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="angleright-fa"
                      ></FontAwesomeIcon>
                    </Link>
                  </li>
                  <li className="active">About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section p-r z-1 pt-5 bg-light-green">
        <div className="container py-md-5 py-0">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-content-box content-box-gap mb-50">
                <div className="section-title section-title-left wow fadeInUp mb-30">
                  <span className="sub-title">About Us</span>
                  <h2>Transforming Plastic Waste into Valuable Resources</h2>
                </div>
                <p className="fs-18">
                  At Almajal, we firmly believe in the transformative power of recycling. We view plastic waste not as a problem, but as an opportunity to create valuable resources that benefit both businesses and the environment. Our commitment to sustainability is exemplified by our dedication to converting plastic waste into valuable, reusable materials.
                </p>
                <div className="choose-item-list wow fadeInDown">
                  <div className="single-choose-item mb-30 d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="check-fa"
                    ></FontAwesomeIcon>
                    <div className="text">
                      <h5 className="mb-0">100% Quality Products</h5>
                    </div>
                  </div>
                  <div className="single-choose-item mb-30 d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="check-fa"
                    ></FontAwesomeIcon>
                    <div className="text">
                      <h5 className="mb-0">Modern Equipments</h5>
                    </div>
                  </div>

                  <div className="single-choose-item mb-30 d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="check-fa"
                    ></FontAwesomeIcon>
                    <div className="text">
                      <h5 className="mb-0">Reasonable Price</h5>
                    </div>
                  </div>
                </div>
                <div className="about-button wow fadeInUp">
                  <Link to="/service">
                    <a className="main-btn btn-yellow">
                      Learn More Us
                      <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        className="circle-fa"
                      ></FontAwesomeIcon>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-four_image-box text-right p-r mb-3 wow fadeInRight">
                <img src={about1} className="about-img_one" alt="" />
                <img src={about2} className="about-img_two" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why-choose-two p-r z-1 py-5">
        <div className="container py-md-5 py-0">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-12">
              <div className="choose-two_content-box">
                <div className="section-title section-title-left mb-40 wow fadeInLeft">
                  <span className="sub-title">Why Choose Us</span>
                  <h2>Innovative Plastic Recycling Solutions</h2>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-12">
              <div className="row pl-lg-70">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="single-chart-item text-center mb-4 wow fadeInUp">
                    <ProgressBar value={75} color="#0b9444" />
                    <div className="text">
                      <h5>Recycling Solutions</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="single-chart-item text-center mb-4 wow fadeInDown">
                    <ProgressBar value={86} color="#0b9444" />
                    <div className="text">
                      <h5>Advanced Technology</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="single-chart-item text-center mb-4 wow fadeInUp">
                    <ProgressBar value={53} color="#0b9444" />
                    <div className="text">
                      <h5>Environmentally Responsible</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="farmers-team_two bg-light-green py-5">
        <div className="container py-md-5 py-0">
          <div className="row justify-content-center">
            <div className="col-lg-6 text-center">
              <div className="section-title section-title-left mb-50 wow fadeInLeft">
                <span className="sub-title">Our Team</span>
                <h2>We Have Lot’s Of Experience Team Members</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="team-member_one text-center mb-md-0 mb-40 wow fadeInUp">
                <div className="member-img">
                  <img src={team1} alt="" className="rounded-circle shadow" />
                </div>
                <div className="member-info">
                  <h3 className="title">
                    <Link href="/farmers">
                      <a>Mahammad zuber</a>
                    </Link>
                  </h3>
                  <p className="position">CEO</p>
                  <ul className="d-flex justify-content-center">
                    <li className="px-2">
                      <a href="#">
                        <FontAwesomeIcon
                          icon={faPhoneAlt}
                          className="phone-fa phone-icon"
                        ></FontAwesomeIcon>
                      </a>
                    </li>
                    <li className="px-2">
                      <a href="#">
                        <FontAwesomeIcon
                          icon={faEnvelopeOpen}
                          className="phone-fa"
                        ></FontAwesomeIcon>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="team-member_one text-center mb-md-0 mb-40 wow fadeInDown">
                <div className="member-img">
                  <img src={team2} alt="" className="rounded-circle shadow" />
                </div>
                <div className="member-info">
                  <h3 className="title">
                    <Link href="/farmers">
                      <a>Mohammed Fayum</a>
                    </Link>
                  </h3>
                  <p className="position">Director</p>
                  <ul className="d-flex justify-content-center">
                    <li className="px-2">
                      <a href="#">
                        <FontAwesomeIcon
                          icon={faPhoneAlt}
                          className="phone-fa phone-icon"
                        ></FontAwesomeIcon>
                      </a>
                    </li>
                    <li className="px-2">
                      <a href="#">
                        <FontAwesomeIcon
                          icon={faEnvelopeOpen}
                          className="phone-fa"
                        ></FontAwesomeIcon>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
              <div className="team-member_one text-center mb-4 wow fadeInUp">
                <div className="member-img">
                  <img src={team3} alt="" className="rounded-circle shadow" />
                </div>
                <div className="member-info">
                  <h3 className="title">
                    <Link href="/farmers">
                      <a>M.Ashfaq</a>
                    </Link>
                  </h3>
                  <p className="position">Relationship Manager</p>
                  <ul className="d-flex justify-content-center">
                    <li className="px-2">
                      <a href="#">
                        <FontAwesomeIcon
                          icon={faPhoneAlt}
                          className="phone-fa phone-icon"
                        ></FontAwesomeIcon>
                      </a>
                    </li>
                    <li className="px-2">
                      <a href="#">
                        <FontAwesomeIcon
                          icon={faEnvelopeOpen}
                          className="phone-fa"
                        ></FontAwesomeIcon>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="testimonial-section py-5">
        <div className="container py-md-5 py-0">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-10">
              <div className="section-title text-center mb-40 wow fadeInUp">
                <span className="sub-title">Clients Feedback</span>
                <h2>What’s Our Clients Say About Our Products</h2>
              </div>
            </div>
          </div>
          <Slider {...testimonialSliderOne} className="testimonial-slider-one">
            <div className="testimonial-item text-center wow fadeInDown">
              <div className="author-thumb">
                <img src={testimonial1} alt="author Image" />
              </div>
              <div className="testimonial-content">
                <p>
                  “Sit amet consectetu escing elit sed do eiusmod tempor
                  incididunt ut labore dolore magna aliqua. Quis ipsum
                  suspendisse ultrices gravic darisus comoe”{" "}
                </p>
                <div className="quote">
                  <div className="right-quotation">
                    <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
                  </div>
                </div>
                <div className="author-title">
                  <h4>Michael R. Jordan</h4>
                  <p className="position">CEO &amp; Founder</p>
                </div>
              </div>
            </div>
            <div className="testimonial-item text-center wow fadeInUp">
              <div className="author-thumb">
                <img src={testimonial2} alt="author Image" />
              </div>
              <div className="testimonial-content">
                <p>
                  “Sit amet consectetu escing elit sed do eiusmod tempor
                  incididunt ut labore dolore magna aliqua. Quis ipsum
                  suspendisse ultrices gravic darisus comoe”{" "}
                </p>
                <div className="quote">
                  <div className="right-quotation">
                    <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
                  </div>
                </div>
                <div className="author-title">
                  <h4>Nathan A. Caswell</h4>
                  <p className="position">Senior Manager</p>
                </div>
              </div>
            </div>
            <div className="testimonial-item text-center wow fadeInDown">
              <div className="author-thumb">
                <img src={testimonial3} alt="author Image" />
              </div>
              <div className="testimonial-content">
                <p>
                  “Sit amet consectetu escing elit sed do eiusmod tempor
                  incididunt ut labore dolore magna aliqua. Quis ipsum
                  suspendisse ultrices gravic darisus comoe”{" "}
                </p>
                <div className="quote">
                  <div className="right-quotation">
                    <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
                  </div>
                </div>
                <div className="author-title">
                  <h4>Somalia D. Silva</h4>
                  <p className="position">Business Manager</p>
                </div>
              </div>
            </div>
            <div className="testimonial-item text-center wow fadeInUp">
              <div className="author-thumb">
                <img src={testimonial4} alt="author Image" />
              </div>
              <div className="testimonial-content">
                <p>
                  “Sit amet consectetu escing elit sed do eiusmod tempor
                  incididunt ut labore dolore magna aliqua. Quis ipsum
                  suspendisse ultrices gravic darisus comoe”{" "}
                </p>
                <div className="quote">
                  <div className="right-quotation">
                    <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
                  </div>
                </div>
                <div className="author-title">
                  <h4>Michael D. Slaughter</h4>
                  <p className="position">Web Developer</p>
                </div>
              </div>
            </div>
            <div className="testimonial-item text-center wow fadeInDown">
              <div className="author-thumb">
                <img src={testimonial2} alt="author Image" />
              </div>
              <div className="testimonial-content">
                <p>
                  “Sit amet consectetu escing elit sed do eiusmod tempor
                  incididunt ut labore dolore magna aliqua. Quis ipsum
                  suspendisse ultrices gravic darisus comoe”{" "}
                </p>
                <div className="quote">
                  <div className="right-quotation">
                    <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
                  </div>
                </div>
                <div className="author-title">
                  <h4>Nathan A. Caswell</h4>
                  <p className="position">Senior Manager</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section> */}

      <section className="fun-fact-one py-5 pb-200 bg-">
        <div className="container py-md-5 py-0 mb-5">
          <div className=" wow fadeInDown">
            <div className="counter-inner-box text-center">
              <OrgariumCounter />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default AboutUs;
