import React from 'react'
import Layout from "../../layouts/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from 'react';
import { useState } from 'react';
const ProductList = ({ title, products }) => {

    const [modal, setModal] = useState(false)
    const [modalImage, setModalImage] = useState("");

    useEffect(() => window.scrollTo(0, 0), [])

    const openModal = (image) => {
        setModal(true);
        setModalImage(image);
    }

    return (
        <>
            <Layout>
                <Helmet>
                    <title>{title} - Almajal Recycling</title>
                </Helmet>
                <section
                    className="page-banner bg_cover position-relative z-1"
                    style={{ backgroundImage: `url(https://media.istockphoto.com/id/1428199273/photo/stone-platform-in-tropical-forest-for-product-presentation-and-green-wall.webp?b=1&s=170667a&w=0&k=20&c=zi1cYpmWvBMxCe7IiAh4icaOakcBERvZEDTiFDWmLaY=)` }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10">
                                <div className="page-title">
                                    <h1>{title}</h1>
                                    <ul className="breadcrumbs-link">
                                        <li>
                                            <Link to="/">
                                                Home{" "}
                                                <FontAwesomeIcon
                                                    icon={faAngleRight}
                                                    className="angleright-fa"
                                                ></FontAwesomeIcon>
                                            </Link>
                                        </li>
                                        <li className="active">{title}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5 ">
                    {/* <div className="container pt-md-4 pb-md-5 py-0 mb-5"> */}

                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10 pt-md-4 pt-0 pb-md-5">
                            <div className="section-title text-center mb-40 wow fadeInUp">
                                <span className="sub-title">{title}</span>
                                <h2>Different Categories of <span className='text-success w-100 '>{title}</span></h2>
                            </div>
                        </div>
                    </div>

                    {
                        products.map((product, index) => {
                            return (
                                <div key={index} className={`py-5 ${index%2===0 && 'bg-light-green'}`}>

                                    <div className="container py-md-4">

                                        <h3 className='mb-4 productName-head '>{product.name}</h3>

                                        <div className={`row ${index === products.length-1 ? 'pb-70' : ''}`}>
                                            {
                                                product.image.map((productImg, i) => {
                                                    return (
                                                        <div className="col-xl-3 col-md-4 " key={i} onClick={() => openModal(productImg)}>
                                                            <div className='product-card shadow'>
                                                                <img src={productImg} alt="productImage" className='w-100' />
                                                                <div>
                                                                    <h5>{product.name}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {/* </div> */}

                </section>
            </Layout>


            {/* <!-- The Modal --> */}
            <div className={`img-modal ${modal ? "show-img-modal" : ""}`}>
                <span className="closeModal" onClick={() => setModal(false)}>&times;</span>
                <img src={modalImage} id="img01" />
            </div>
        </>
    )
}

export default ProductList