import React from 'react'

import ProductList from './ProductList'

// Products images

import blueDrum1 from "../../assets/images/products/HDPE BLOW GRADE/BLUE DRUM 200 LT/1-blue-drum-200LT.webp"
import blueDrum2 from "../../assets/images/products/HDPE BLOW GRADE/BLUE DRUM 200 LT/2-blue-drum-200LT.webp"
import blueDrum3 from "../../assets/images/products/HDPE BLOW GRADE/BLUE DRUM 200 LT/3-blue-drum-200LT.jpeg"

import hdpeSmallGallon1 from "../../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-1.jpeg"
import hdpeSmallGallon2 from "../../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-2.jpeg"
import hdpeSmallGallon3 from "../../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-3.jpeg"
import hdpeSmallGallon4 from "../../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-4.jpeg"


import milkBottle1 from "../../assets/images/products/HDPE BLOW GRADE/MILK BOTTLE/milk-bottle1.jpeg"
import milkBottle2 from "../../assets/images/products/HDPE BLOW GRADE/MILK BOTTLE/milk-bottle2.jpeg"

import pe100_1 from "../../assets/images/products/HDPE BLOW GRADE/PE 100/pe-100-1.jpeg"
import pe100_2 from "../../assets/images/products/HDPE BLOW GRADE/PE 100/pe-100-2.jpeg"
import pe100_3 from "../../assets/images/products/HDPE BLOW GRADE/PE 100/pe-100-3.jpeg"

import smallGallon1 from "../../assets/images/products/HDPE BLOW GRADE/SMALL GALLON/small-gallon1.jpeg"
import smallGallon2 from "../../assets/images/products/HDPE BLOW GRADE/SMALL GALLON/small-gallon2.jpeg"
import smallGallon3 from "../../assets/images/products/HDPE BLOW GRADE/SMALL GALLON/small-gallon3.jpeg"

// ----------------------------------------------------------------------------------------------------

const HdpeBlowGrade1 = () => {

  const products = [
    {
      name: "Blue Drum 200 LT",
      image: [blueDrum1, blueDrum2, blueDrum3]
    },
    {
      name: "HDPE Small Gallon",
      image: [hdpeSmallGallon1, hdpeSmallGallon2, hdpeSmallGallon3]
    },
    {
      name: "Milk Bottle",
      image: [milkBottle1, milkBottle2]
    },
    {
      name: "PE 100",
      image: [pe100_1, pe100_2, pe100_3]
    },
    {
      name: "HDPE blow 1 to 10 litre",
      image: [smallGallon1, smallGallon2, smallGallon3]
    }
  ]

  return (
    <>
      <ProductList title="Hdpe Blow Grade" products={products} />
    </>
  )
}

export default HdpeBlowGrade1