import React from 'react'
import ProductList from './ProductList'

// Product images

import hdpeCarrat1 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate1.jpeg"
import hdpeCarrat2 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate2.jpeg"
import hdpeCarrat3 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate3.jpeg"
import hdpeCarrat4 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate4.jpeg"
import hdpeCarrat5 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate5.jpeg"
import hdpeCarrat6 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate6.jpeg"
import hdpeCarrat7 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate7.jpeg"

import hdpePallate1 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PALLATE/hdpe pallate1.jpeg"
import hdpePallate2 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PALLATE/hdpe pallate2.jpeg"
import hdpePallate3 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PALLATE/hdpe pallate3.jpeg"
import hdpePallate4 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PALLATE/hdpe pallate4.jpeg"
import hdpePallate5 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PALLATE/hdpe pallate5.jpeg"

import hdpeRegrinde1 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PROTECTOR REGRINDE/hdpe protector regrinde1.jpeg"
import hdpeRegrinde2 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PROTECTOR REGRINDE/hdpe protector regrinde2.jpeg"
import hdpeRegrinde3 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PROTECTOR REGRINDE/hdpe protector regrinde3.jpeg"
import hdpeRegrinde4 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PROTECTOR REGRINDE/hdpe protector regrinde4.jpeg"

// ------------------------------------------------------------------------------------------------------------------

const ProductHdpeInjection = () => {

    const products = [
        {
          name: "HDPE Carrate",
          image: [ hdpeCarrat1, hdpeCarrat2, hdpeCarrat3, hdpeCarrat4, hdpeCarrat5, hdpeCarrat6, hdpeCarrat7 ]
        },
        {
          name: "HDPE Pallate",
          image: [ hdpePallate1, hdpePallate2, hdpePallate3, hdpePallate4, hdpePallate5]
        },
        {
          name: "HDPE Protector Regrinde",
          image: [ hdpeRegrinde1, hdpeRegrinde2, hdpeRegrinde3, hdpeRegrinde4]
        }
      ]

    return (
        <>
            <ProductList title="Product Hdpe Injection" products={products} />
        </>
    )
}

export default ProductHdpeInjection