import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";

import team1 from "../assets/images/team/mohd-zuber1.png";

const TestimonialSlider = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });
  const thumbs = {
    dots: false,
    autoplay: true,
    speed: 800,
    speed: 500,
    arrows: false,
    focusOnSelect: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
    ],
  };
  const slider = {
    arrows: false,
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="row justify-content-center">
      <div className="col-lg-11 pb-150 pt-40">
        <Slider
          {...slider}
          asNavFor={nav1}
          ref={(slider) => setSlider2(slider)}
          className="testimonial-slider-four wow fadeInDown"
        >
          <div className="testimonial-item-three d-flex">
            <div className="author-thumb">
              <img src={team1} alt="" style={{height: '160px', objectFit: 'cover'}} />
            </div>
            <div className="testimonial-content">
              <p style={{ lineHeight: '30px' }}>
                Recycling is the process of collecting and processing materials that would otherwise be thrown away as trash and turning them into new products. Recycling can benefit your community, the economy and the environment.
              </p>
              <div className="author-title-qoute d-flex">
                <div className="quote">
                  <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
                </div>
                <div className="author-title">
                  <h4>Mahammad zuber</h4>
                  <p className="position">CEO</p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};
export default TestimonialSlider;
