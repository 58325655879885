import { useEffect } from "react";
import { scrollTopFun } from "../layouts/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

const ScrollTop = () => {
  useEffect(() => {
    scrollTopFun();
  }, []);
  const onClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <a
      href="#"
      className="back-to-top"
      onClick={() => onClick()}
      id="scroll-top"
      style={{ display: "inline-block" }}
    >
      <FontAwesomeIcon icon={faAngleUp} ></FontAwesomeIcon>
    </a>
  );
};
export default ScrollTop;
