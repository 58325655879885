import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import Slider from "react-slick";
import { heroSliderTwo } from "../layouts/sliderProps";
import slide from "../assets/images/banner/banner1.png";
import slide4 from "../assets/images/banner/banner2.jpeg";
import slide5 from "../assets/images/banner/banner3.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";

export default class Hero4Slider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    return (
      <section className="hero-area-four">
        <Slider
          {...heroSliderTwo}
          ref={(c) => (this.slider = c)}
          className="hero-slider-two"
        >
          <div className="single-slider p-r z-1">
            <div
              className="image-layer bg_cover first-slide"
              style={{
                backgroundImage: `url(${slide4})`
              }}

            />
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-7 col-lg-8">
                  <div className="hero-content text-white">
                    <span
                      className="tag-line"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                    >
                      Join the Recycling Revolution
                    </span>
                    <h1
                      data-animation="fadeInUp"
                      data-delay=".5s"
                      className="banner-first"
                    >
                      Transforming plastic waste into a sustainable future
                    </h1>
                    <div
                      className="hero-button"
                      data-animation="fadeInDown"
                      data-delay=".6s"
                    >
                      <Link to="/about">
                        <a className="main-btn btn-yellow">
                          Learn About Us
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            className="circle-fa"
                          ></FontAwesomeIcon>
                        </a>
                      </Link>
                      <Link to="/service">
                        <a className="main-btn btn-white">
                          Latest Service
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            className="circle-fa"
                          ></FontAwesomeIcon>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="single-slider p-r z-1">
            <div
              className="image-layer bg_cover"
              style={{
                backgroundImage: `url(${slide5})`,
              }}
            />
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-7 col-lg-8">
                  <div className="hero-content text-white">
                    <span
                      className="tag-line"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                    >
                      Plastic Recycling Made Easy
                    </span>
                    <h1
                      data-animation="fadeInUp"
                      data-delay=".5s"
                      className="banner-first"
                    >
                      Green Solutions for a Blue Planet
                    </h1>
                    <div
                      className="hero-button"
                      data-animation="fadeInDown"
                      data-delay=".6s"
                    >
                      <Link to="/about">
                        <a className="main-btn btn-yellow">
                          Learn About Us
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            className="circle-fa"
                          ></FontAwesomeIcon>
                        </a>
                      </Link>
                      <Link to="/service">
                        <a className="main-btn btn-white">
                          Latest Service
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            className="circle-fa"
                          ></FontAwesomeIcon>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="single-slider p-r z-1">
            <div
              className="image-layer bg_cover"
              style={{
                backgroundImage: `url(${slide})`,
              }}
            />
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-7 col-lg-8">
                  <div className="hero-content text-white">
                    <span
                      className="tag-line"
                      data-animation="fadeInDown"
                      data-delay=".4s"
                    >
                      Plastic Recycling Made Easy
                    </span>
                    <h1
                      data-animation="fadeInUp"
                      data-delay=".5s"
                      className="banner-first"
                    >
                      Green Solutions for a Blue Planet
                    </h1>
                    <div
                      className="hero-button"
                      data-animation="fadeInDown"
                      data-delay=".6s"
                    >
                      <Link to="/about">
                        <a className="main-btn btn-yellow">
                          Learn About Us
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            className="circle-fa"
                          ></FontAwesomeIcon>
                        </a>
                      </Link>
                      <Link to="/service">
                        <a className="main-btn btn-white">
                          Latest Service
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            className="circle-fa"
                          ></FontAwesomeIcon>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
        <div className="hero-arrows">
          <div className="prev slick-arrow" onClick={this.previous}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="circle-fa"
            ></FontAwesomeIcon>
          </div>
          <div className="next slick-arrow" onClick={this.next}>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="circle-fa"
            ></FontAwesomeIcon>
          </div>
        </div>
      </section>
    );
  }
}
