import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Accordion } from "react-bootstrap";
import Slider from "react-slick";
import OrgariumAcc from "../layouts/OrgariumAcc";
import Hero4Slider from "../layouts/Hero4Slider";
import Layout from "../layouts/Layout";
import {
  logoSlider,
  projectsSliderThree,
  recentProductSlider,
  testimonialSliderFive,
} from "../layouts/sliderProps";
import { Helmet } from "react-helmet";
import signT from "../assets/images/banner/introduction-first.jpg";
import earthImg from "../assets/images/banner/world-earth.gif"

// products
import blol_grade from "../assets/images//products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-3.jpeg";
import pp_regrinde from "../assets/images/products/PP REGRINDE/PP PALLATE/pp-pallate1.jpeg"
import hdpe_injection from "../assets/images/products/PRODUCT HDPE INJECTION/HDPE PALLATE/hdpe pallate3.jpeg";

import faq1 from "../assets/images/banner/recycling-concept-flat-lay.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowRight,
  faQuoteRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

// products image data
import { allProducts } from "./Products/AllProducts";

const Home = () => {
  const [active, setActive] = useState("collapse0");

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [])

  return (
    <div>
      <Layout header={4} footer={4}>
        <Helmet>
          <title>Home - Almajal Recycling</title>
        </Helmet>
        <Hero4Slider />
        <section className="about-section-two p-r z-1">
          <div className="container">
            <div className="about-wrapper">
              <div className="row align-items-center pt-md-5 pt-0 pb-80">
                <div className="col-lg-5 my-5 my-md-0 pt-md-4">
                  <div className="about-five_image-box wow fadeInLeft position-relative">
                    <img src={signT} alt="About Image" className="shadow" />
                    <img src={earthImg} alt="" className="earth-image" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-content-box content-box-gap pl-lg-60 pr-lg-70  wow fadeInRight">
                    <div className="section-title section-title-left mb-35 ">
                      <span className="sub-title">About Us</span>
                      <h2>End-to-End Plastic Waste Management Services</h2>
                    </div>
                    <p className="fs-18 mb-0">
                      At Almajal, we are dedicated to revolutionizing the world of plastic recycling. With our primary focus on plastic materials, we play a pivotal role in providing high-quality recycled plastics to clients across the globe. Our unwavering commitment to sustainability and environmental responsibility drives everything we do.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="category-section pb-250">
          <div className="container">
            <div className="category-wrapper plactic-product-bg  bg-dark">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-10">
                  <div className="section-title text-center mb-50 wow fadeInDown">
                    <span className="sub-title text-white">Services</span>
                    <h2 className="text-white">Integrated Services for Plastic Waste Management</h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-around">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 ">
                  <div className="single-category-box text-center mb-40 wow fadeInUp bg-light">
                    <Link to="/hdpe-blow-grade" className="w-100" >
                      <div className="icon">
                        <img src={blol_grade} alt="" className="rounded" />
                      </div>
                      <div className="text">
                        <h3 className="title">
                          HDPE Blow Grade
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div className="single-category-box text-center mb-40 wow fadeInUp bg-light">
                    <Link to="/pp-regrinde" className="w-100">
                      <div className="icon">
                        <img src={pp_regrinde} alt="" className="rounded" />
                      </div>
                      <div className="text">
                        <h3 className="title">
                          PP REGRINDE
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div className="single-category-box text-center mb-40 wow fadeInDown bg-light">
                    <Link to="/product-hdpe-injection" className="w-100">
                      <div className="icon">
                        <img src={hdpe_injection} alt="" className="rounded" />
                      </div>
                      <div className="text">
                        <h3 className="title">
                          HDPE INJECTION
                        </h3>
                      </div>
                    </Link>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </section>

        <section className="project-section pb-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-10">
                <div className="section-title text-center mb-60 wow fadeInDown">
                  <span className="sub-title pt-4">Gallery</span>
                  <h2 className="sub-heading">Gallery Insights</h2>
                </div>
              </div>
            </div>
            <Slider {...projectsSliderThree} className="projects-slider-three">
              {
                allProducts.map((ProductImg, i) => {
                  return (
                    <div className="project-item-four wow fadeInDown">
                      <div className="img-holder h-100">
                        <img src={ProductImg.image} alt="" className="h-100" style={{ objectFit: "cover" }} />
                        <div className="hover-portfolio">
                          <div className="hover-content w-100">
                            <h3 className="title text-center">
                              <Link to="/portfolio-details">{ProductImg.name}</Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </Slider>
          </div>
        </section>

        <section className="cta-section ">
          <div className="container-fluid px-0">
            <div className="cta-wrap-two bg_cover al-ctasection py-5">
              <div className="container py-md-5 py-5">
                <div className="row h-100 justify-content-end align-items-center">
                  <div className="col-lg-7 col-md-9">
                    <div className="cta-content-box wow fadeInDown">
                      <span className="tag-line">Get In Touch</span>
                      <h2 className="touch-get">
                        Join the Plastic Recycling Movement{" "}
                      </h2>
                      <p className="fs-18">
                        We invite you to join us in our journey towards a more sustainable world. By choosing Almajal, you are not only accessing premium recycled plastics but also participating in the global movement to reduce plastic waste and safeguard our environment.
                      </p>
                      <Link to="/contact">
                        <a className="main-btn bordered-btn">
                          Meet With Us
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            className="circle-fa"
                          ></FontAwesomeIcon>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faqs-section py-5 mb-5 bg-white">
          <div className="container py-md-5 py-0 mb-5">
            <div className="row">
              <div className="col-lg-6">
                <div className="faq-one_img-box mb-md-0 mb-40">
                  <img
                    src={faq1}
                    className="faq-img-two fadeInDown"
                    alt="Faq Image"
                    style={{ objectFit: 'contain' }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="faq-one_content-box pl-lg-70 wow fadeInRight">
                  <div className="section-title mb-40">
                    <span className="sub-title">Why Choose Us</span>
                    <h2>Why People’s Choose Recycling Products</h2>
                  </div>

                  <div className="accordian-element">
                    <Accordion
                      defaultActiveKey="collapse0"
                      className="accordion"
                      id="accordionOne"
                    >
                      <OrgariumAcc />
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="testimonial-section py-5">
          <div className="container py-md-5 py-0">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-10">
                <div className="section-title text-center mb-50 wow fadeInDown">
                  <span className="sub-title">Clients Feedback</span>
                  <h2>What’s Our Client Say About Our Recycling Products</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center ">
              <div className="col-xl-12">
                <div className="row align-items-center">
                  <div className="col-lg-7 mt-1 al-testimonial">
                    <div className="testimonial-wrapper pl-lg-30 wow fadeInRight">
                      <Slider
                        {...testimonialSliderFive}
                        className="testimonial-slider-five mb-55"
                      >
                        <div className="testimonial-item-four">
                          <div className="testimonial-content">
                            <div className="client-review-box d-flex">
                              <div className="client-thumb mb-10">
                                <img src={testimonial2} alt="" />
                              </div>
                              <div className="review-box mb-10">
                                <span>Quality </span>
                                <ul className="ratings">
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <p className="fs-18">
                              Sed ut perspiciatis unde omnis iste natuses
                              voluptatem accusantium doloremque lauda ntium
                              totam rem aperiam eaque ipsa quaeab inventore
                              veritatis et quasi architecto beatae vitae dicta
                              sunt explicabo. Nemo enim ipsam voluptatem
                              voluptas sit aspernatur{" "}
                            </p>
                            <div className="author-title-qoute d-flex">
                              <div className="quote">
                                <FontAwesomeIcon
                                  icon={faQuoteRight}
                                ></FontAwesomeIcon>
                              </div>
                              <div className="author-title">
                                <h4>Michael R. Jordan</h4>
                                <p className="position">CEO &amp; Founder</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-item-four">
                          <div className="testimonial-content">
                            <div className="client-review-box d-flex justify-content-between">
                              <div className="client-thumb mb-10">
                                <img src={testimonial2} alt="" />
                              </div>
                              <div className="review-box mb-10">
                                <span>Quality </span>
                                <ul className="ratings">
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <p className="fs-18">
                              Sed ut perspiciatis unde omnis iste natuses
                              voluptatem accusantium doloremque lauda ntium
                              totam rem aperiam eaque ipsa quaeab inventore
                              veritatis et quasi architecto beatae vitae dicta
                              sunt explicabo. Nemo enim ipsam voluptatem
                              voluptas sit aspernatur{" "}
                            </p>
                            <div className="author-title-qoute d-flex">
                              <div className="quote">
                                <FontAwesomeIcon
                                  icon={faQuoteRight}
                                ></FontAwesomeIcon>
                              </div>
                              <div className="author-title">
                                <h4>Jordan Michael</h4>
                                <p className="position">CEO &amp; Founder</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-item-four">
                          <div className="testimonial-content">
                            <div className="client-review-box d-flex">
                              <div className="client-thumb mb-10">
                                <img src={testimonial2} alt="" />
                              </div>
                              <div className="review-box mb-10">
                                <span>Quality </span>
                                <ul className="ratings">
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <p className="fs-18">
                              Sed ut perspiciatis unde omnis iste natuses
                              voluptatem accusantium doloremque lauda ntium
                              totam rem aperiam eaque ipsa quaeab inventore
                              veritatis et quasi architecto beatae vitae dicta
                              sunt explicabo. Nemo enim ipsam voluptatem
                              voluptas sit aspernatur{" "}
                            </p>
                            <div className="author-title-qoute d-flex">
                              <div className="quote">
                                <FontAwesomeIcon
                                  icon={faQuoteRight}
                                ></FontAwesomeIcon>
                              </div>
                              <div className="author-title">
                                <h4>Michael R. Jordan</h4>
                                <p className="position">CEO &amp; Founder</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="testimonial-item-four">
                          <div className="testimonial-content">
                            <div className="client-review-box d-flex justify-content-between">
                              <div className="client-thumb mb-10">
                                <img src={testimonial2} alt="" />
                              </div>
                              <div className="review-box mb-10">
                                <span>Quality </span>
                                <ul className="ratings">
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faStar}
                                      className="star-fa"
                                    ></FontAwesomeIcon>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <p className="fs-18">
                              Sed ut perspiciatis unde omnis iste natuses
                              voluptatem accusantium doloremque lauda ntium
                              totam rem aperiam eaque ipsa quaeab inventore
                              veritatis et quasi architecto beatae vitae dicta
                              sunt explicabo. Nemo enim ipsam voluptatem
                              voluptas sit aspernatur{" "}
                            </p>
                            <div className="author-title-qoute d-flex">
                              <div className="quote">
                                <FontAwesomeIcon
                                  icon={faQuoteRight}
                                ></FontAwesomeIcon>
                              </div>
                              <div className="author-title">
                                <h4>Jordan Michael</h4>
                                <p className="position">CEO &amp; Founder</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slider>
                      <div />
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div className="testimonial-img-box text-end al-img wow fadeInLeft">
                      <img src={member} alt="testimonial image" style={{ height: '420px', width: '100%', objectFit: 'cover', objectPosition: 'top' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </Layout>
    </div>
  );
};

export default Home;
