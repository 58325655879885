import React, { useEffect, useState, Fragment } from "react";
import OffcanvasPanel from "../layouts/OffcanvasPanel";
import MobileHeader from "../layouts/MobileHeader";
import useWindowSize from "../layouts/useWindowSize";
import { stickyNav } from "../layouts/utils";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
} from "react-router-dom";
import logo from "../assets/images/logo/almajal-logo.png";
import stickyLogo from "../assets/images/banner/sticky.png";
import mobileLogo from "../assets/images/banner/sticky.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCircleArrowRight,
  faClockFour,
  faList,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

import uaeFlag from "../assets/images/uae-flag.jpg"

const Header = ({ header }) => {
  useEffect(() => {
    stickyNav();
  }, []);
  const [overlayPanel, setOverlayPanel] = useState(false);
  const togglePanel = () => setOverlayPanel(!overlayPanel);

  const { width } = useWindowSize();

  useEffect(() => {
    const headers = document.querySelectorAll(".header-navigation");
    headers.forEach((header) => {
      if (width <= 1199) {
        header.classList.add("breakpoint-on");
      } else {
        header.classList.remove("breakpoint-on");
      }
      // toggle
      const toggleBtn = header.getElementsByClassName("navbar-toggler")[0],
        overlay = header.getElementsByClassName("nav-overlay")[0],
        menu = header.getElementsByClassName("nav-menu")[0];
      toggleBtn.addEventListener("click", () => {
        overlay.classList.add("active");
        menu.classList.add("menu-on");
      });
      overlay.addEventListener("click", () => {
        overlay.classList.remove("active");
        menu.classList.remove("menu-on");
      });
    });
  }, [width]);
  // }

  return (
    <div>
      <Fragment>
        <OffcanvasPanel overlyPanel={overlayPanel} togglePanel={togglePanel} />
        <header className="header-area">
          <div className="header-top-bar top-bar-two dark-black-bg">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-xl-4 col-lg-12 col-md-12 col-6">
                  <div className="top-bar-left d-flex align-items-center"></div>
                </div>
                <div className="col-xl-8 col-lg-12 col-md-12 black-topbar">
                  <div className="top-bar-right">
                    <span className="text">
                      <FontAwesomeIcon
                        icon={faClockFour}
                        className="clock-fa text-white"
                      ></FontAwesomeIcon>
                      Opening Hours : Saturday - Thusday, 08:00 am - 05:00pm
                    </span>
                    <ul className="social-link">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-navigation navigation-four">
            <div className="nav-overlay" />
            <div className="container">
              <div className="primary-menu">
                <div className="site-branding">
                  <Link to="/">
                    <a className="brand-logo">
                      <img src={logo} alt="Site Logo" className="company-logo" />
                    </a>
                  </Link>
                  <Link to="/">
                    <a className="sticky-logo">
                      <img src={stickyLogo} alt="Site Logo" />
                    </a>
                  </Link>
                </div>
                <div className="nav-inner-menu">
                  <div className="bar-item"></div>
                  <div className="nav-menu">
                    <div className="mobile-logo mb-30 d-block d-xl-none text-center">
                      <Link href="/">
                        <a className="brand-logo">
                          <img src={mobileLogo} alt="Site Logo" />
                        </a>
                      </Link>
                    </div>
                    <div className="call-button text-center">
                      <span>
                        <FontAwesomeIcon
                          icon={faPhoneAlt}
                          className="phone-fa phone-icon"
                        ></FontAwesomeIcon>
                        <a href="tel:+012(345)678">+012 (345) 678</a>
                      </span>
                    </div>
                    <Menu />
                    <MobileHeader />
                    <div className="menu-button">
                      <Link href="/contact">
                        <a className="main-btn bordered-btn">Get a Quote</a>
                      </Link>
                    </div>
                  </div>
                  <div className="nav-right-item d-flex align-items-center">
                    <div className="call-button">
                      <span>
                        <FontAwesomeIcon
                          icon={faPhoneAlt}
                          className="phone-fa phone-icon"
                        ></FontAwesomeIcon>
                        <img src={uaeFlag} style={{width: '26px', marginRight: '10px'}} alt="" />
                        <a href="tel:+971 566533119">
                          +971 566533119</a>
                      </span>
                    </div>
                    <div className="menu-button">
                      <Link to="/contact">
                        <a className="main-btn bordered-btn bordered-yellow get-quote">
                          Contact Us
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            className="circle-fa"
                          ></FontAwesomeIcon>
                        </a>
                      </Link>
                    </div>
                    <div className="navbar-toggler">
                    <FontAwesomeIcon
                            icon={faBars} 
                          ></FontAwesomeIcon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Fragment>
    </div>
  );
};
const Menu = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <nav className="main-menu d-none d-xl-block">
      <ul>
        <li className="menu-item">
          <Link
            to="/"
            className={splitLocation[1] === "" ? "active" : ""}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={splitLocation[1] === "/about" ? "active" : ""}
          >
            About
          </Link>
        </li>
        <li className="menu-item">
          <Link
            to="/service"
            className={splitLocation[1] === "/service" ? "active" : ""}
          >
            Services
          </Link>
        </li>
        <li className="menu-item">
          <Link
            to="/contact"
            className={splitLocation[1] === "/contact" ? "active" : ""}
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
