import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Slider from "react-slick";
import TestimonialSlider from "../layouts/TestimonialSlider";
import Layout from "../layouts/Layout";
import { Helmet } from "react-helmet";
import aboutBg from "../assets/images/banner/services_banner.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { projectsSliderTwo } from "../layouts/sliderProps";


import resource1 from "../assets/images/banner/sprout.png";
import resource2 from "../assets/images/banner/recycle-bin.png";
import resource3 from "../assets/images/banner/energy-control.png";
import resource4 from "../assets/images/banner/crisis.png";
import resource5 from "../assets/images/banner/profit.png";
import resource6 from "../assets/images/banner/planet-earth.png";

import process1 from "../assets/images/collection.png"
import process2 from "../assets/images/pickup.png"
import process3 from "../assets/images/processing.png"
import process4 from '../assets/images/recycling.png'
import PopularServices from "./PopularServices";
import { useEffect } from "react";

const Services = () => {

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>Services - Almajal Recycling</title>
      </Helmet>
      <section
        className="page-banner bg_cover position-relative z-1"
        style={{ backgroundImage: `url(${aboutBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <div className="page-title">
                <h1>Services</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link to="/">
                      Home{" "}
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="angleright-fa"
                      ></FontAwesomeIcon>
                    </Link>
                  </li>
                  <li className="active">Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-section py-5">
        <div className="container py-md-5 py-0">
          <div className="row align-items-center">
            <div className="col-lg-6 ">
              <div className="section-title section-title-left mb-4 wow fadeInLeft">
                <span className="sub-title">Plastic Recycling</span>
                <h2>Reducing Landfill Dependence through Plastic Recycling</h2>
                <p className="wow fs-18 fadeInRight mt-3">
                  The process of recovering plastic waste and turning it into new products is known as plastic recycling. Reducing the quantity of plastic waste that ends up in landfills or the environment—such as rivers and oceans—is the aim of plastic recycling.
                </p>
                <p className="wow fs-18 fadeInRight mt-3">
                  Reducing the quantity of plastic waste in landfills and the ocean, preserving resources, and lowering glasshouse gas emissions are just a few advantages of recycling plastic.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <p className="wow fs-18 pl-20 mt-3 fadeInRight">
                But not all plastics can be recycled, and recycled plastic frequently has a lower quality than virgin plastic. It's critical that we use less plastic in our daily lives and that any plastic waste we produce is disposed of correctly.
              </p>
              <p className="wow fs-18 pl-20 mt-3 fadeInRight">
                Consider donating the book if it's still in good shape! Book donations are frequently accepted by non-profits, schools, churches, and charities. The book can be recycled if it is not in a usable condition. Hardcover books must first have their covers removed in order to be recycled; paperback books can be recycled exactly as they are.
              </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-xl-2 col-lg-4 col-md-4 col-6 ">
              <div className="service-box text-center mb-md-0 mb-4 wow fadeInUp">
                <div className="icon">
                  <img
                    src={resource6}
                    alt="Resource Conservation"
                    className="conservation"
                  />
                </div>
                <div className="text">
                  <h3 className="title">
                    <a>Corporate Responsibility</a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-6 ">
              <div className="service-box text-center mb-md-0 mb-4 wow fadeInDown">
                <div className="icon">
                  <img
                    src={resource1}
                    alt="Resource Conservation"
                    className="conservation"
                  />
                </div>
                <div className="text">
                  <h3 className="title">
                    <a>Resource Conservation</a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-6 ">
              <div className="service-box text-center mb-md-0 mb-4 wow fadeInUp">
                <div className="icon">
                  <img
                    src={resource2}
                    alt="Resource Conservation"
                    className="conservation"
                  />
                </div>
                <div className="text">
                  <h3 className="title">
                    <a>Waste Reduction</a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-6 ">
              <div className="service-box text-center mb-md-0 mb-4 wow fadeInDown">
                <div className="icon">
                  <img
                    src={resource3}
                    alt="Resource Conservation"
                    className="conservation"
                  />
                </div>
                <div className="text">
                  <h3 className="title">
                    <a> Energy Savings </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-6 ">
              <div className="service-box text-center mb-md-0 mb-4 wow fadeInUp">
                <div className="icon">
                  <img
                    src={resource4}
                    alt="Resource Conservation"
                    className="conservation"
                  />
                </div>
                <div className="text">
                  <h3 className="title">
                    <a>Emissions Reduction</a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-6 ">
              <div className="service-box text-center mb-md-0 mb-2 wow fadeInDown">
                <div className="icon">
                  <img
                    src={resource5}
                    alt="Resource Conservation"
                    className="conservation"
                  />
                </div>
                <div className="text">
                  <h3 className="title">
                    <a>Economic Benefits</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* new section */}
      <section className="popular-services py-5">
        <div className="container py-md-5 py-0">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
              <div className="section-title text-center mb-50 wow fadeInUp">
                <span className="sub-title text-white">Popular Services</span>
                <h2 className="text-white">A Sustainable Solution for Waste Management.</h2>
              </div>
            </div>
          </div>
          <PopularServices />
        </div>
      </section>

      {/* old section */}

      {/* <section className="popular-service py-5">
        <div className="container py-md-5 py-0">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
              <div className="section-title text-center mb-50 wow fadeInDown">
                <span className="sub-title">Popular Services</span>
                <h2>A Sustainable Solution for Waste Management.</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="single-service-item mb-4 wow fadeInUp">
                <img src={icon} alt="Icon" className="hdpe-blow" />
                <div className="text">
                  <h3>
                    <Link to="#">HDPE BLOW GRADE</Link>
                  </h3>
                  <p className="fs-18">Natus error sit volupt ateme accus antium dolores</p>
                </div>
              </div>
              <div className="single-service-item mb-4 wow fadeInDown">
                <img src={icon1} alt="Icon" className="hdpe-blow" />
                <div className="text">
                  <h3>
                    <Link to="#">HDPE INJECTION GRADE</Link>
                  </h3>
                  <p className="fs-18">Natus error sit volupt ateme accus antium dolores</p>
                </div>
              </div>
              <div className="single-service-item mb-4 wow fadeInUp">
                <img src={icon2} alt="Icon" className="hdpe-blow" />
                <div className="text">
                  <h3>
                    <Link to="#">HDPE FILP GRADE</Link>
                  </h3>
                  <p className="fs-18">Natus error sit volupt ateme accus antium dolores</p>
                </div>
              </div>
              <div className="single-service-item mb-4 wow fadeInDown">
                <img src={icon3} alt="Icon" className="hdpe-blow" />
                <div className="text">
                  <h3>
                    <Link to="#">PP MIX COLORLDPE AGLO</Link>
                  </h3>
                  <p className="fs-18">Natus error sit volupt ateme accus antium dolores</p>
                </div>
              </div>
              <div className="single-service-item mb-md-0 mb-4 wow fadeInUp">
                <img src={icon4} alt="Icon" className="hdpe-blow" />
                <div className="text">
                  <h3>
                    <Link to="#">LLDPE AGLOPVC K67 GRADE PULVERIZE</Link>
                  </h3>
                  <p className="fs-18">Natus error sit volupt ateme accus antium dolores</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="img-holder my-4 wow fadeInDown">
                <img src={serviceImg} alt="Service Image" />
              </div>
            </div>
            <div className="col-lg-4 services-all">
              <div className="single-service-item mb-4 card-rtl wow fadeInDown">
                <img src={icon5} alt="Icon" className="plastic-recycle" />
                <div className="text">
                  <h3>
                    <Link to="#">PE 100</Link>
                  </h3>
                  <p className="fs-18">Natus error sit volupt ateme accus antium dolores</p>
                </div>
              </div>
              <div className="single-service-item mb-4 card-rtl wow fadeInUp">
                <img src={icon6} alt="Icon" className="plastic-recycle" />
                <div className="text">
                  <h3>
                    <Link to="#">PE 80</Link>
                  </h3>
                  <p className="fs-18">Natus error sit volupt ateme accus antium dolores</p>
                </div>
              </div>
              <div className="single-service-item mb-4 card-rtl wow fadeInDown">
                <img src={icon7} alt="Icon" className="plastic-recycle" />
                <div className="text">
                  <h3>
                    <Link to="#">PE 65PP PALLET REGARDING</Link>
                  </h3>
                  <p className="fs-18">Natus error sit volupt ateme accus antium dolores</p>
                </div>
              </div>
              <div className="single-service-item mb-4 card-rtl wow fadeInDown">
                <img src={icon7} alt="Icon" className="plastic-recycle" />
                <div className="text">
                  <h3>
                    <Link to="#">PVC INJECTION PULVERIZE</Link>
                  </h3>
                  <p className="fs-18">Natus error sit volupt ateme accus antium dolores</p>
                </div>
              </div>
              <div className="single-service-item card-rtl wow fadeInUp">
                <img src={icon7} alt="Icon" className="plastic-recycle" />
                <div className="text">
                  <h3>
                    <Link to="#">PVC K65 GRADE PULVERIZE</Link>
                  </h3>
                  <p className="fs-18">Natus error sit volupt ateme accus antium dolores</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* ----------------------------------------- */}

      <section className="projects-section py-5 bg-light-green">
        <div className="container py-md-5 py-0">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-10">
              <div className="section-title text-center mb-50">
                <span className="sub-title">Process</span>
                <h2 className="sub-heading">
                  Our Working Process
                  {/* We’ve Done Many Other Projects Let’s See Gallery Insights */}
                </h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-center ">
            <div className="col-md-3 col-sm-6 col-11 working-process mb-md-0 mb-5 px-md-3 px-4">
              <div>
                <img src={process1} alt="" />
                <div className="process-step">01</div>
              </div>
              <h5>Collection</h5>
              <p className="fs-18 mt-2">Recycling is the process of making used or unwanted products into new. </p>
            </div>

            <div className="col-md-3 col-sm-6 col-11 working-process mb-md-0 mb-5 px-md-3 px-4">
              <div>
                <img src={process2} alt="" />
                <div className="process-step">02</div>
              </div>
              <h5>Pickup</h5>
              <p className="fs-18 mt-2">Recycling is the process of making used or unwanted products into new. </p>
            </div>

            <div className="col-md-3 col-sm-6 col-11 working-process mb-md-0 mb-5 px-md-3 px-4">
              <div>
                <img src={process3} alt="" />
                <div className="process-step">03</div>
              </div>
              <h5>Processing</h5>
              <p className="fs-18 mt-2">Recycling is the process of making used or unwanted products into new. </p>
            </div>

            <div className="col-md-3 col-sm-6 col-11 working-process px-md-3 px-4">
              <div>
                <img src={process4} alt="" />
                <div className="process-step">04</div>
              </div>
              <h5>Recycling</h5>
              <p className="fs-18 mt-2">Recycling is the process of making used or unwanted products into new. </p>
            </div>
          </div>

          {/* <Slider {...projectsSliderTwo} className="projects-slider-two">
            <div className="project-item-two wow fadeInUp">
              <div className="img-holder">
                <img src={member2} alt="" />
                <div className="hover-portfolio">
                  <div className="hover-content">
                    <h3 className="title">
                      <Link href="/portfolio-details">
                        <a>Transforming Plastic Waste</a>
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-item-two wow fadeOutDown">
              <div className="img-holder">
                <img src={member3} alt="" />
                <div className="hover-portfolio">
                  <div className="hover-content">
                    <h3 className="title">
                      <Link href="/portfolio-details">
                        <a>Transforming Plastic Waste</a>
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-item-two wow fadeInUp">
              <div className="img-holder">
                <img src={member4} alt="" />
                <div className="hover-portfolio">
                  <div className="hover-content">
                    <h3 className="title">
                      <Link href="/portfolio-details">
                        <a>Transforming Plastic Waste</a>
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-item-two wow fadeInUp">
              <div className="img-holder">
                <img src={member4} alt="" />
                <div className="hover-portfolio">
                  <div className="hover-content">
                    <h3 className="title">
                      <Link href="/portfolio-details">
                        <a>Transforming Plastic Waste</a>
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </Slider> */}
        </div>
      </section>

      <section className="testimonial-three py-md-5 py-0">
        <div className="container">
          <TestimonialSlider />
        </div>
      </section>
    </Layout>
  );
};
export default Services;
