import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCircleArrowRight,
  faEnvelopeOpen,
  faHouse,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import uaeFlag from "../assets/images/uae-flag.jpg"

import swal from 'sweetalert';
import axios from "axios";
import { useState } from "react";

const Footer = () => {

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);


  const onSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true)
    const formData = new FormData();

    formData.append("email", email);
    formData.append("phone", phone);

    try {
      await axios({
        method: "post",
        url: "https://ztpl.net/almajal/api/subscribe.php",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {

        setLoading(false)
        if (response.data.status == "success") {
          swal("", "Subscribed Successfully!", "success")
          setEmail('')
          setPhone('')
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  return (

    <footer className="footer-default footer-white dark-black-bg">

      <div className="container position-relative">
        {/* social media icons */}
        {/* <div className="media-icons">
        <div className="media-icon">
          <i className="fab fa-facebook-f" />
        </div>
        <div className="media-icon">
          <i className="fab fa-twitter" />
        </div>
        <div className="media-icon">
          <i className="fab fa-linkedin" />
        </div>
        <div className="media-icon">
          <i className="fab fa-youtube" />
        </div>
      </div> */}
        <div className="footer-newsletter footer-newsletter-two yellow-bg">
          <div className="row">
            <div className="col-xl-3">
              <div className="footer-text">
                <h5>Subscribe Our Newsletter To Get More Updates</h5>
              </div>
            </div>
            <div className="col-xl-9">
              <div className="newsletter-form">
                <form onSubmit={onSubscribe}>
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="form_group">
                        <input
                          type="email"
                          className="form_control"
                          placeholder="Email Address"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form_group">
                        <input
                          type="text"
                          className="form_control"
                          placeholder="Phone Number"
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form_group">
                        <button className="main-btn subscribe-btn">
                          {loading ? "Subscribing..." : "Subscribe Now"}
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            className="circle-fa"
                          ></FontAwesomeIcon>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-widget pb-25">
          <FooterMenu />
        </div>
        <div className="footer-copyright border-top-white-1">
          <div className="row">
            <div className="col-md-5">
              <div className="copyright-text text-left">
                <p>© 2023 Al Majal Recycling. All Rights Reserved</p>
              </div>
            </div>

            <div className="col-md-2 col-7 py-md-0 py-3 social-link social-media-icons d-flex align-items-center justify-content-between">
              <a href="#" style={{ fontSize: '20px' }}><i className="fab fa-facebook-f" /></a>
              <a href="#" style={{ fontSize: '20px' }}><i className="fab fa-twitter" /></a>
              <a href="#" style={{ fontSize: '20px' }}><i className="fab fa-linkedin" /></a>
              <a href="#" style={{ fontSize: '20px' }}><i className="fab fa-youtube" /></a>
            </div>

            <div className="col-md-5">
              <div className="copyright-text text-right">
                <p>
                  Designed and Developed by{" "}
                  <Link
                    to="https://www.zithas.com/"
                    target="_blank"
                    className="footer-widget"
                  >
                    Zithas Technologies
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}


const FooterMenu = () => (
  <div className="row">
    <div className="col-md-5 col-sm-12">
      <div className="footer-widget about-widget mb-40 wow fadeInDown">
        <h4 className="widget-title">About Us</h4>
        <div className="footer-about-content">
          <p className="fs-18">
            At Almajal, we firmly believe in the transformative power of recycling. We view plastic waste not as a problem, but as an opportunity to create valuable resources that benefit both businesses and the environment.
          </p>
          {/* <div className="social-box">
            <h4 className="mb-15">Follow On</h4>
            <ul className="social-link">
              <li>
                <a href="#">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-linkedin" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-youtube" />
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
    <div className="col-md-3 col-sm-12">
      <div className="footer-widget footer-nav-widget mb-40 wow fadeInUp">
        <h4 className="widget-title">Quick Links</h4>
        <div className="footer-widget-nav">
          <ul>
            <li>
              <Link to="/">
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className="angle-fa"
                ></FontAwesomeIcon>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about">
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className="angle-fa"
                ></FontAwesomeIcon>
                About
              </Link>
            </li>
            <li>
              <Link to="/service">
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className="angle-fa"
                ></FontAwesomeIcon>
                Services
              </Link>
            </li>
            <li>
              <Link to="/contact">
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className="angle-fa"
                ></FontAwesomeIcon>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="col-md-4 col-sm-12">
      <div className="footer-widget recent-post-widget mb-40 wow fadeInDown">
        <h4 className="widget-title">Contact Details</h4>
        <div className="widget-address mb-3">
          <FontAwesomeIcon
            icon={faHouse}
            className="phone-fa"
          ></FontAwesomeIcon>
          AI Saja's Industrial Oasis, Sharjah, U.A.E
        </div>
        <div className="widget-phone mb-3">
          <Link to="tel:+971 566533119">
            <FontAwesomeIcon
              icon={faPhoneAlt}
              className="phone-fa phone-icon"
            ></FontAwesomeIcon>
            <img src={uaeFlag} style={{ width: '26px', marginRight: '10px' }} alt="" />
            +971 566533119
          </Link>
        </div>
        <div className="widget-email">
          <Link to="mailto:zuber@almajal-recycle.com">
            <FontAwesomeIcon
              icon={faEnvelopeOpen}
              className="phone-fa"
            ></FontAwesomeIcon>
            zuber@almajal-recycle.com
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
