import Accordion from "react-bootstrap/Accordion";

const OrgariumAcc = () => {
  return (
    <div className="card mb-20 " >
      <div className="card-header" >
        <Accordion defaultActiveKey="0" className="">
          <Accordion.Item className="bg-white" eventKey="0">
            <Accordion.Header className="">ASSURED QUALITY</Accordion.Header>
            <Accordion.Body>
              Assured quality with a dedicated team of professionals which are capable of providing top quality goods.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="bg-white" eventKey="1">
            <Accordion.Header>24 / 7 SUPPORT</Accordion.Header>
            <Accordion.Body>
              We have established 24/7 help desk services for out reputed customer and inbound technical support team.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="bg-white" eventKey="2">
            <Accordion.Header>CUSTOMER SATISFACTION</Accordion.Header>
            <Accordion.Body>
              Paking 25kg to 1 ton jumbo bag. We satisfy all our customer needs.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="bg-white" eventKey="3">
            <Accordion.Header>COMPETITIVE RATES</Accordion.Header>
            <Accordion.Body>
              With large scale procurement we can provide you the best competitive rates in markets.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};
export default OrgariumAcc;
