import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from "../layouts/Layout";
import contact1 from "../assets/images/banner/contact.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import aboutBg from "../assets/images/banner/intro.jpg";
import {
  faEnvelopeOpenText,
  faMapMarkerAlt,
  faPhone,
  faCircleArrowRight,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import uaeFlag from "../assets/images/uae-flag.jpg"
import swal from "sweetalert";


const Contact = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmitContact = async (e) => {
    e.preventDefault();
    setLoading(true)

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);

    try {
      const response = await axios({
        method: "post",
        url: "https://ztpl.net/almajal/api/connect.php",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setLoading(false)

        if (response.data.status == "success") {
          swal("", "Message Sent Successfully", "success")

          setEmail('')
          setName('')
          setMessage('')
        }

      });
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>Contact - Almajal Recycling</title>
      </Helmet>
      <section
        className="page-banner bg_cover position-relative z-1"
        style={{ backgroundImage: `url(${aboutBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <div className="page-title">
                <h1>Contact Us</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link to="/">
                      Home{" "}
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="angleright-fa"
                      ></FontAwesomeIcon>
                    </Link>
                  </li>
                  <li className="active">Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-information-one overflow-hidden p-r z-1 py-5 ">
        <div className="py-md-4">
          <div className="information-img_one wow fadeInRight ">
            <img src={contact1} alt="Imaged" className="opacity-75" />
          </div>
          <div className="container">
            <div className="row " style={{ width: "100%" }}>
              <div className="col-xl-9 col-lg-12">
                <div className="contact-two_information-box">
                  <div className="section-title section-title-left mb-50 wow fadeInUp">
                    <span className="sub-title">Get In Touch</span>
                    <h2 className="heading-contact">
                      Join the Plastic Recycling Movement: Connect with Our Team
                    </h2>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <div className="information-item-two info-one mb-30 wow fadeInDown">
                        <div className="icon ">
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                          ></FontAwesomeIcon>
                        </div>
                        <div className="info">
                          <h5>Our Locations</h5>
                          <div className="row">
                            <p className="fs-18 col-md-4 border-left mb-md-0 mb-3"> AI Saja's Industrial Oasis, Sharjah, U.A.E</p>
                            <p className="fs-18 col-md-4 border-left mb-md-0 mb-3"> Unit 8, Dock Offices, Surrey Quays Road, London SE16 2XU </p>
                            <p className="fs-18 col-md-4 border-left mb-md-0 mb-3"> Al hamdaniya District Jeddah 23743 </p>
                          </div>
                        </div>
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="map-marker"
                        ></FontAwesomeIcon>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="information-item-two mb-30 info-two wow fadeInUp">
                        <div className="icon">
                          <FontAwesomeIcon
                            icon={faEnvelopeOpenText}
                          ></FontAwesomeIcon>
                        </div>
                        <div className="info">
                          <h5>Email Address</h5>
                          <p>
                            <a to="mailto:zuber@almajal-recycle.com" target="_blank">
                              zuber@almajal-recycle.com
                            </a>
                          </p>
                        </div>
                        <FontAwesomeIcon
                          icon={faEnvelopeOpenText}
                          className="map-marker"
                        ></FontAwesomeIcon>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="information-item-two mb-30 info-three wow fadeInDown">
                        <div className="icon">
                          <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                        </div>
                        <div className="info">
                          <h5>Phone Number</h5>
                          <p>
                            <img src={uaeFlag} style={{ width: '26px', marginRight: '10px' }} alt="" />
                            <Link to="tel:+971 566533119">+971 566533119</Link>
                          </p>
                        </div>
                        <FontAwesomeIcon
                          icon={faPhone}
                          className="map-marker"
                        ></FontAwesomeIcon>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-9">
                      <p className="fs-18">
                        Natus error sit voluptatem accusantium doloremque
                        laudatium, totam rem aperiam eaque ipsa quae abllo
                        inventore veritatis et quase
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container pb-5">
        <div className="row pb-5 mb-md-5 mb-0">
          <div className="col-md-6 pb-40">
            <div className="map-box ">
              <iframe src="https://maps.google.com/maps?q=new%20york&t=&z=13&ie=UTF8&iwloc=&output=embed" />

              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14842.1922361108!2d55.61780568846351!3d25.374127142965225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f180ac689873%3A0x9d5393f5df3a4a72!2sAl%20Saja&#39;a%20Industrial%20Oasis!5e0!3m2!1sen!2sin!4v1700919127800!5m2!1sen!2sin" height={405} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>

          <div className="col-md-6 pb-40">
            <div className="contact-three_content-box rounded contact-form-section">
              <div className="section-title section-title-left mb-30">
                <span className="sub-title text-white">Get In Touch</span>
                <h3 className="text-white">Want to Recycle Plastics?</h3>
              </div>
              <div className="contact-form">
                {/* onSubmit={onSubmitContact} */}
                <form onSubmit={onSubmitContact}>
                  <div className="form_group">
                    <input
                      type="text"
                      className="form_control"
                      placeholder="Full Name"
                      name="name"
                      required=""
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  <div className="form_group">
                    <input
                      type="email"
                      className="form_control"
                      placeholder="Email Address"
                      name="email"
                      required=""
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                  <div className="form_group">
                    <textarea
                      className="form_control"
                      placeholder="Write Message"
                      name="message"
                      defaultValue={""}
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}

                    />
                  </div>
                  <div className="form_group">
                    <button className="main-btn btn-yellow">
                      {loading ? "Sending Your Message..." : "Send Us Message"}
                      <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        className="circle-fa"
                      ></FontAwesomeIcon>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>


    </Layout>
  );
};

// <section className="contact-three pb-170 wow fadeInUp">
//         <div className="container">
//           <div className="row justify-content-end">
//             <div className="col-xl-7 col-lg-10">
//               <div className="contact-three_content-box">
//                 <div className="section-title section-title-left mb-60">
//                   <span className="sub-title">Get In Touch</span>
//                   <h2>Want to Recycle Plastics?</h2>
//                 </div>
//                 <div className="contact-form">
//                   {/* onSubmit={onSubmitContact} */}
//                   <form>
//                     <div className="form_group">
//                       <input
//                         type="text"
//                         className="form_control"
//                         placeholder="Full Name"
//                         name="name"
//                         required=""
//                       // onChange={(e) => setName(e.target.value)}
//                       />
//                     </div>
//                     <div className="form_group">
//                       <input
//                         type="email"
//                         className="form_control"
//                         placeholder="Email Address"
//                         name="email"
//                         required=""
//                       // onChange={(e) => setEmail(e.target.value)}
//                       />
//                     </div>
//                     <div className="form_group">
//                       <textarea
//                         className="form_control"
//                         placeholder="Write Message"
//                         name="message"
//                         defaultValue={""}
//                       // onChange={(e) => setMessage(e.target.value)}
//                       />
//                     </div>
//                     <div className="form_group">
//                       <button className="main-btn btn-yellow">
//                         Send Us Message
//                         <FontAwesomeIcon
//                           icon={faCircleArrowRight}
//                           className="circle-fa"
//                         ></FontAwesomeIcon>
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

export default Contact;
