import blueDrum1 from "../../assets/images/products/HDPE BLOW GRADE/BLUE DRUM 200 LT/1-blue-drum-200LT.webp"
import blueDrum2 from "../../assets/images/products/HDPE BLOW GRADE/BLUE DRUM 200 LT/2-blue-drum-200LT.webp"
import blueDrum3 from "../../assets/images/products/HDPE BLOW GRADE/BLUE DRUM 200 LT/3-blue-drum-200LT.jpeg"

import hdpeSmallGallon1 from "../../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-1.jpeg"
import hdpeSmallGallon2 from "../../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-2.jpeg"
import hdpeSmallGallon3 from "../../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-3.jpeg"
import hdpeSmallGallon4 from "../../assets/images/products/HDPE BLOW GRADE/HDPE SMALL GALLON/hdpe-small-gallon-4.jpeg"

import milkBottle1 from "../../assets/images/products/HDPE BLOW GRADE/MILK BOTTLE/milk-bottle1.jpeg"
import milkBottle2 from "../../assets/images/products/HDPE BLOW GRADE/MILK BOTTLE/milk-bottle2.jpeg"

import pe100_1 from "../../assets/images/products/HDPE BLOW GRADE/PE 100/pe-100-1.jpeg"
import pe100_2 from "../../assets/images/products/HDPE BLOW GRADE/PE 100/pe-100-2.jpeg"
import pe100_3 from "../../assets/images/products/HDPE BLOW GRADE/PE 100/pe-100-3.jpeg"

import smallGallon1 from "../../assets/images/products/HDPE BLOW GRADE/SMALL GALLON/small-gallon1.jpeg"
import smallGallon2 from "../../assets/images/products/HDPE BLOW GRADE/SMALL GALLON/small-gallon2.jpeg"
import smallGallon3 from "../../assets/images/products/HDPE BLOW GRADE/SMALL GALLON/small-gallon3.jpeg"

// -------------------------------------------------------------------------------------------------

import ppFruat1 from "../../assets/images/products/PP REGRINDE/PP FRUAT BASKET/pp-fruat-basket1.jpeg"
import ppFruat2 from "../../assets/images/products/PP REGRINDE/PP FRUAT BASKET/pp-fruat-basket2.jpeg"

import ppPallate from "../../assets/images/products/PP REGRINDE/PP PALLATE/pp-pallate.jpeg"
import ppPallate1 from "../../assets/images/products/PP REGRINDE/PP PALLATE/pp-pallate1.jpeg"
import ppPallate2 from "../../assets/images/products/PP REGRINDE/PP PALLATE/pp-pallate2.jpeg"
import ppPallate3 from "../../assets/images/products/PP REGRINDE/PP PALLATE/pp-pallate3.jpeg"
import ppPallate4 from "../../assets/images/products/PP REGRINDE/PP PALLATE/pp-pallate4.jpeg"

import ppcpBattery1 from "../../assets/images/products/PP REGRINDE/PPCP BATTERY/ppcp-battery1.jpeg"
import ppcpBattery2 from "../../assets/images/products/PP REGRINDE/PPCP BATTERY/ppcp-battery2.jpeg"
import ppcpBattery3 from "../../assets/images/products/PP REGRINDE/PPCP BATTERY/ppcp-battery3.jpeg"

import ppcpWhiteBucket1 from "../../assets/images/products/PP REGRINDE/PPCP WHITE BUCKET/pp-regrinde.jpeg"
import ppcpWhiteBucket2 from "../../assets/images/products/PP REGRINDE/PPCP WHITE BUCKET/ppcp-white-bucket1.jpeg"
import ppcpWhiteBucket3 from "../../assets/images/products/PP REGRINDE/PPCP WHITE BUCKET/ppcp-white-bucket2.jpeg"
import ppcpWhiteBucket4 from "../../assets/images/products/PP REGRINDE/PPCP WHITE BUCKET/ppcp-white-bucket3.jpeg"

// -------------------------------------------------------------------------------------------------

import hdpeCarrat1 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate1.jpeg"
import hdpeCarrat2 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate2.jpeg"
import hdpeCarrat3 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate3.jpeg"
import hdpeCarrat4 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate4.jpeg"
import hdpeCarrat5 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate5.jpeg"
import hdpeCarrat6 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate6.jpeg"
import hdpeCarrat7 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE CARRATE/hdpe-carrate7.jpeg"

import hdpePallate1 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PALLATE/hdpe pallate1.jpeg"
import hdpePallate2 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PALLATE/hdpe pallate2.jpeg"
import hdpePallate3 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PALLATE/hdpe pallate3.jpeg"
import hdpePallate4 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PALLATE/hdpe pallate4.jpeg"
import hdpePallate5 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PALLATE/hdpe pallate5.jpeg"

import hdpeRegrinde1 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PROTECTOR REGRINDE/hdpe protector regrinde1.jpeg"
import hdpeRegrinde2 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PROTECTOR REGRINDE/hdpe protector regrinde2.jpeg"
import hdpeRegrinde3 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PROTECTOR REGRINDE/hdpe protector regrinde3.jpeg"
import hdpeRegrinde4 from "../../assets/images/products/PRODUCT HDPE INJECTION/HDPE PROTECTOR REGRINDE/hdpe protector regrinde4.jpeg"

export const allProducts = [

    {
        name: "Blue Drum 200 LT",
        image: blueDrum1
    },
    {
        name: "Blue Drum 200 LT",
        image: blueDrum2
    },
    {
        name: "Blue Drum 200 LT",
        image: blueDrum3
    },

    {
        name: "HDPE Small Gallon",
        image: hdpeSmallGallon1
    },
    {
        name: "HDPE Small Gallon",
        image: hdpeSmallGallon2
    },
    {
        name: "HDPE Small Gallon",
        image: hdpeSmallGallon3
    },
    {
        name: "Milk Bottle",
        image: milkBottle1
    },
    {
        name: "Milk Bottle",
        image: milkBottle2
    },
    {
        name: "PE 100",
        image: pe100_1
    },
    {
        name: "PE 100",
        image: pe100_2
    },
    {
        name: "PE 100",
        image: pe100_3
    },
    {
        name: "Small Gallon",
        image: smallGallon1
    },
    {
        name: "Small Gallon",
        image: smallGallon2
    },
    {
        name: "Small Gallon",
        image: smallGallon3
    },

    // -----------------------------

    {
        name: "PP Pallate",
        image: ppPallate
    },
    {
        name: "PP Pallate",
        image: ppPallate1
    },
    {
        name: "PP Pallate",
        image: ppPallate2
    },
    {
        name: "PP Pallate",
        image: ppPallate3
    },
    {
        name: "PP Pallate",
        image: ppPallate4
    },

    {
        name: "PP Fruat Basket",
        image: ppFruat1
    },
    {
        name: "PP Fruat Basket",
        image: ppFruat2
    },

    {
        name: "PPCP Battery",
        image: ppcpBattery1
    },
    {
        name: "PPCP Battery",
        image: ppcpBattery2
    },
    {
        name: "PPCP Battery",
        image: ppcpBattery3
    },

    {
        name: "PPCP White Bucket",
        image: ppcpWhiteBucket1
    },
    {
        name: "PPCP White Bucket",
        image: ppcpWhiteBucket2
    },
    {
        name: "PPCP White Bucket",
        image: ppcpWhiteBucket3
    },
    {
        name: "PPCP White Bucket",
        image: ppcpWhiteBucket4
    },

    // ----------------------------------------
    {
        name: "HDPE Carrate",
        image: hdpeCarrat1
    },
    {
        name: "HDPE Carrate",
        image: hdpeCarrat2
    },
    {
        name: "HDPE Carrate",
        image: hdpeCarrat3
    },
    {
        name: "HDPE Carrate",
        image: hdpeCarrat4
    },
    {
        name: "HDPE Carrate",
        image: hdpeCarrat5
    },
    {
        name: "HDPE Carrate",
        image: hdpeCarrat6
    },
    {
        name: "HDPE Carrate",
        image: hdpeCarrat7
    },
    {
        name: "HDPE Pallate",
        image: hdpePallate1
    },
    {
        name: "HDPE Pallate",
        image: hdpePallate2
    },
    {
        name: "HDPE Pallate",
        image: hdpePallate3
    },
    {
        name: "HDPE Pallate",
        image: hdpePallate4
    },
    {
        name: "HDPE Pallate",
        image: hdpePallate5
    },

    {
        name: "HDPE Protector Regrinde",
        image: hdpeRegrinde1
    },
    {
        name: "HDPE Protector Regrinde",
        image: hdpeRegrinde2
    },
    {
        name: "HDPE Protector Regrinde",
        image: hdpeRegrinde3
    },
    {
        name: "HDPE Protector Regrinde",
        image: hdpeRegrinde4
    },
]